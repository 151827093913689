import request from '@/utils/request';
export var getList = function getList(data) {
  return request({
    url: '/account/user',
    method: 'get',
    params: data
  });
};
export var saveAccount = function saveAccount(data) {
  return request({
    url: '/account/user',
    method: 'post',
    data: data
  });
};
export var updateAccount = function updateAccount(data) {
  return request({
    url: '/account/user',
    method: 'put',
    data: data
  });
};
export var deleteAccount = function deleteAccount(data) {
  return request({
    url: '/account/user',
    method: 'delete',
    data: data
  });
};
export var activeAccount = function activeAccount(data) {
  return request({
    url: '/account/user/active',
    method: 'post',
    data: data
  });
};
export var getLogs = function getLogs(data) {
  return request({
    url: '/account/logs',
    method: 'get',
    params: data
  });
};